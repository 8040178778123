import React, { useState, useEffect, lazy, Suspense }  from 'react';

//App routing (version react-router-dom 5.2.0 to get Switch component)
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';

// Helmet - SEO optimisation for meta description and page title tags
import { Helmet } from "react-helmet";

//Components imports (lazy/suspense method, to load components only when needed)
import ScrollArrow from './components/styckynav/ScrollArrow';

const Homepage = lazy(() => import('./components/homepage/Homepage'));
const Article = lazy(() => import('./components/articles/Article'));
const News = lazy(() => import('./components/homepage/News'));
const ArticleNews = lazy(() => import('./components/articles/ArticleNews'));
const Culture = lazy(() => import('./components/homepage/Culture'));
const ArticleCulture = lazy(() => import('./components/articles/ArticleCulture'));
const MarketData = lazy(() => import('./components/homepage/Market'));

function App() {
  
  return (
    <Suspense fallback={""}>
      <Router>
        {/* Dynamic tags for SEO optimisation */}
        <Helmet>
          <meta charSet="utf-8" />
            <title>Sneakers-Game</title>
          <meta name="description" content="Sneakers Game est la première plateforme française dédiée à la sneakers et au marché du resell. Notre équipe vous partage les dernières news sneakers, vous conseille pour cop les meilleures paires en retail ou resell, vous aide à investir dans la sneakers et à construire votre stratégie de revente (prédiction de la cote de votre paire, meilleur moment pour revendre, money sizes... ), comprendre le marché de la sneakers et ses acteurs (resellers, bots, retailers, marques...). Notre équipe de passionnés, partage sa connaissance de la culture sneakers et nos stylistes vous aident à construire vos meilleurs looks autour de vos sneakers."
          />
        </Helmet>
        <Switch>
          <Route exact path="/" render={() => <Homepage />} />
          <Route exact path="/news" render={() => <News />} />
          <Route exact path="/sneakers-culture" render={() => <Culture />} />
          <Route exact path="/resell/:id/:slug" render={() => <Article />} />
          <Route exact path="/news/:id/:slug" render={() => <ArticleNews />} />
          <Route exact path="/sneakers-culture/:id/:slug" render={() => <ArticleCulture />} />
          <Route exact path="/market-data" render={() => <MarketData />} />
          <ScrollArrow />
        </Switch>
      </Router>
    </Suspense>
  );
}

export default App;
